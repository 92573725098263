import React from "react"
import styled, { css } from "styled-components"
import { graphql } from "gatsby"

import Layout from "../components/archive/layout"
import SEO from "../components/archive/seo"
import Section from "../components/archive/section"
import Grid from "../components/archive/lib/grid"

const StyledLink = styled.a`
  font-size: 1.8rem;
  line-height: 2.4rem;
  margin: 0;
  margin-top: ${props => props.theme.verticalSpacing(1)};
  margin-bottom: ${props => props.theme.verticalSpacing(1)};
  color: ${props => props.theme.highlightBlue};
  font-weight: 700;
`

const AntiSlavery = ({ data }) => (
  <Layout>
    <SEO title="Anti Slavery" />
    <Section>
      <Grid>
        <Grid.Row>
          <Grid.Col>
            <h1
              css={css`
                font-size: 4rem;
                line-height: 6.4rem;
                font-weight: 300;
              `}
            >
              Anti-Slavery
            </h1>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col>
            <StyledLink href={data.file.publicURL}>
              View Anti-Slavery Policy PDF →
            </StyledLink>
          </Grid.Col>
        </Grid.Row>
      </Grid>
    </Section>
  </Layout>
)

export default AntiSlavery

export const query = graphql`
  query {
    file(relativePath: { eq: "PJD_Anti-slavery_policy.pdf" }) {
      publicURL
    }
  }
`
